import React from 'react'

import useProgressiveImg from './hooks/useProgressiveImage'

const ProgressiveImage = ({ lowQuality, alt, highQuality }) => {
	const [src] = useProgressiveImg(lowQuality, highQuality)

	return <img src={src} alt={alt} />
}

export default ProgressiveImage
