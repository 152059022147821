import * as React from 'react'
import { Helmet } from 'react-helmet'

import { Element, animateScroll as scroll } from 'react-scroll'
import ScrollLink from './ScrollLink'

import ReactMarkdown from 'react-markdown'

import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import '../styles/main.sass'

import { EventEmitter } from 'events'

import landingImage from '../images/portrait.jpg'
import landingBlurredImage from '../images/portrait-blurred.jpg'
import introductionImage from '../images/introduction.jpg'
import introductionBlurredImage from '../images/introduction-blurred.jpg'
import landscapeImage from '../images/contact-landscape.jpg'
import mediaCardImage from '../images/media-card.jpg'
import logoImage from '../images/logo.png'

import ProgressiveImage from './ProgressiveImage'
import MobileMenu from './MobileMenu'

import data from '../../pages/home.yml'

const Demo = ({ index, title, audioFile, emitter, size }) => {
	const ref = React.createRef()
	const onPlaying = () => {
		emitter.emit('play', index)
	}

	emitter.on('play', (i) => {
		if (i !== index) ref.current.audio.current.pause()
	})

	if (size === 'big') {
		return (
			<AudioPlayer
				ref={ref}
				onPlaying={onPlaying}
				customIcons={{
					play: <i className={'icon icon-play'}></i>,
					pause: <i className={'icon icon-pause'}></i>,
				}}
				header={
					<div>
						<div className={'logo-icon'}>
							<img alt='Logo van Mari Ann' src={logoImage} />
						</div>
						<h5>{title || 'Demo'}</h5>
						<p>Mari Ann</p>
					</div>
				}
				customProgressBarSection={[
					RHAP_UI.CURRENT_TIME,
					<div className={'separator'}> / </div>,
					RHAP_UI.DURATION,
				]}
				preload={'auto'}
				customAdditionalControls={[RHAP_UI.PROGRESS_BAR]}
				src={audioFile}
			/>
		)
	} else
		return (
			<AudioPlayer
				ref={ref}
				customIcons={{
					play: <i className={'icon icon-play'}></i>,
					pause: <i className={'icon icon-pause'}></i>,
				}}
				onPlaying={onPlaying}
				customProgressBarSection={[<p>{title}</p>]}
				customAdditionalControls={[]}
				customVolumeControls={[]}
				showJumpControls={false}
				src={audioFile}
				preload={'none'}
			/>
		)
}

const App = () => {
	const [isOpen, setOpen] = React.useState(false)

	const playerEmitter = new EventEmitter()

	const demo = data.demos[0]
	const demosBreakpoint = data.demos.length >= 7 ? 1 : 0
	const videos = [data.video1, data.video2].reduce((map, url) => {
		const matches = url.match(
			//eslint-disable-next-line
			/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i
		)

		if (matches.length >= 2) {
			map.push(matches[1])
		}

		return map
	}, [])

	const scrollToTop = (event) => {
		event.preventDefault()
		scroll.scrollToTop()
	}

	React.useEffect(() => {
		const script = document.createElement('script')

		script.src = '//gigstarter.s3.amazonaws.com/sdk.js'
		script.id = 'gigstarter-sdk'
		script.async = true

		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	})

	return (
		<main>
			<Helmet
				htmlAttributes={{
					lang: 'nl',
				}}
			>
				<title>Mari Ann - {data.motto}</title>
				<meta
					name='keywords'
					content='Mari Ann, zangeres, pianiste, epe, muziek, vocals, piano'
				/>
				<meta
					name='description'
					content={`Van jongs af aan is muziek mijn passie. Ik wil met mijn muziek graag iets toevoegen aan bijzondere momenten in jouw leven.`}
				/>
				<link rel='canonical' href='https://www.mariannmusic.com/' />
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content={`Mari Ann - ${data.motto}`} />
				<meta
					name='twitter:description'
					content={`Van jongs af aan is muziek mijn passie. Ik wil met mijn muziek graag iets toevoegen aan bijzondere momenten in jouw leven.`}
				/>
				<meta property='twitter:image' content={mediaCardImage} />
				<meta property='og:title' content='Mari Ann' />
				<meta property='og:site_name' content={`Mari Ann - ${data.motto}`} />
				<meta property='og:url' content='https://www.mariannmusic.com/' />
				<meta
					property='og:description'
					content={`Van jongs af aan is muziek mijn passie. Ik wil met mijn muziek graag iets toevoegen aan bijzondere momenten in jouw leven.`}
				/>
				<meta property='og:type' content='business.business' />
				<meta property='og:locale' content='nl_NL' />
				<meta property='og:image' content={mediaCardImage} />
			</Helmet>
			<MobileMenu />
			<div className={'block block-landing'}>
				<div className={'block-content'}>
					<div className={'subcontent'}>
						<ul className={'menu'}>
							<li>Home</li>
							<li>
								<ScrollLink to='repertoire'>Repertoire</ScrollLink>
							</li>
							<li>
								<ScrollLink to='biografie'>Biografie</ScrollLink>
							</li>
							<li>
								<ScrollLink to='contact'>Contact</ScrollLink>
							</li>
						</ul>
						<h1>Mari Ann</h1>
						<h3>{data.motto}</h3>
						<p>{data.landing}</p>
						<div className={'demo-container'}>
							<div className={'box'}></div>
							<div className={'demo'}>
								<Demo
									title={demo.titel}
									audioFile={demo['audio-file']}
									emitter={playerEmitter}
									index={-1}
									size={'big'}
								/>
							</div>
							<p className={'desktop-only'}>
								<ScrollLink to='repertoire'>
									Beluister meer demo opnames
								</ScrollLink>
							</p>
						</div>
					</div>
					<div className={'photo-container'}>
						<div className={'image-container'}>
							<ProgressiveImage
								alt={'Portretfoto van Mari Ann bij haar vleugel'}
								lowQuality={landingBlurredImage}
								highQuality={landingImage}
							/>
						</div>
					</div>
				</div>
			</div>
			<Element name='repertoire'>
				<div className={'block block-repertoire'}>
					<div className={'block-content'}>
						<h2>Mijn repertoire</h2>
						<div className={'subcontent'}>
							<div className={'left-container'}>
								{data.demos
									.slice(demosBreakpoint, demosBreakpoint + 3)
									.map((demo, index) => (
										<Demo
											title={demo.titel}
											audioFile={demo['audio-file']}
											key={index}
											emitter={playerEmitter}
											index={index}
										/>
									))}
								<div className={'show-more ' + (isOpen ? 'exhaust' : '')}>
									<button
										aria-label='Bekijk meer demo-opnames'
										onClick={() => {
											setOpen(true)
										}}
									>
										bekijk meer
									</button>
								</div>
								<div className={'more-embeds ' + (isOpen ? 'open' : '')}>
									{data.demos
										.slice(demosBreakpoint + 3, data.demos.length)
										.map((demo, index) => (
											<Demo
												title={demo.titel}
												audioFile={demo['audio-file']}
												key={index}
												emitter={playerEmitter}
												index={3 + index}
											/>
										))}
								</div>
								<p className={'green tablet-only'}>
									Wil je meer muziek horen?{' '}
									<ScrollLink to='contact'>Neem contact met me op</ScrollLink>.
								</p>
							</div>
							<div className={'right-container'}>
								<ReactMarkdown>{data.repertoire}</ReactMarkdown>
							</div>
						</div>
						<p className={'green'}>
							Wil je meer muziek horen?{' '}
							<ScrollLink to='contact'>Neem contact met me op</ScrollLink>.
						</p>
					</div>
				</div>
			</Element>
			<Element name='introductie'>
				<div className={'block block-introduction'}>
					<div className={'block-content'}>
						<div className={'subcontent subcontent-text'}>
							<div className={'left-container'}>
								<div className={'image-container'}>
									<ProgressiveImage
										alt={'Portretfoto van Mari Ann, zittend'}
										lowQuality={introductionBlurredImage}
										highQuality={introductionImage}
									/>
								</div>
							</div>
							<div className={'right-container'}>
								<div className={'image-container floating-image'}>
									<ProgressiveImage
										alt={'Portretfoto van Mari Ann, zittend'}
										lowQuality={introductionBlurredImage}
										highQuality={introductionImage}
									/>
								</div>
								<ReactMarkdown
									components={{
										a: (props) => (
											<a href={props.href} target='_blank' rel='noreferrer'>
												{props.children}
											</a>
										),
									}}
								>
									{data.introductie}
								</ReactMarkdown>
							</div>
						</div>
						<div className={'subcontent subcontent-videos'}>
							<div className={'left-container'}>
								<div className={'video'}>
									<iframe
										width='564'
										height='317'
										src={`https://www.youtube.com/embed/${videos[0]}`}
										frameBorder='0'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
										title='Embedded youtube video'
									/>
								</div>
							</div>
							<div className={'right-container desktop-only'}>
								<div className={'video'}>
									<iframe
										width='564'
										height='317'
										src={`https://www.youtube.com/embed/${videos[1]}`}
										frameBorder='0'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
										title='Embedded youtube video'
									/>
								</div>
							</div>
						</div>
						<p className={'centered'}>
							Op mijn{' '}
							<a
								target='_blank'
								rel='noreferrer'
								href={data.youtube}
								className={'with-icon'}
							>
								YouTube-kanaal
							</a>{' '}
							vind je meer filmpjes
						</p>
					</div>
				</div>
			</Element>
			<div className={'block block-text'}>
				<div className={'block-content'}>
					<div className={'subcontent'}>
						<Element name='biografie'>
							<div className={'left-container'}>
								<div className={'z-index'}>
									<h2>Biografie</h2>
									<ReactMarkdown
										components={{
											a: (props) => (
												<a href={props.href} target='_blank' rel='noreferrer'>
													{props.children}
												</a>
											),
										}}
									>
										{data.biografie}
									</ReactMarkdown>
								</div>
							</div>
						</Element>
						<Element name='contact'>
							<div className={'right-container'}>
								<div className='header-flex'>
									<div className='flex-grow'>
										<h2>Contact</h2>
									</div>
									<div>
										{data.gigstarter === true ? (
											<div
												className='gigstarter-button'
												data-slug='marianne-keys-and-vocals'
												data-version='1'
											>
												<a
													href='https://www.gigstarter.nl/artists/marianne-keys-and-vocals'
													target={'_blank'}
													rel='noreferrer'
												>
													<img
														src='https://gigstarter.s3.amazonaws.com/boek_mij.png'
														alt='Boek Mari Ann'
													/>
												</a>
											</div>
										) : (
											''
										)}
									</div>
								</div>
								<p>{data['tekst-boven']}</p>
								<p className={'contact-info email'}>
									<a href={`mailto:${data.email}`}>
										{(() => {
											const email = data.email
											const parts = email.split('@')

											if (parts.length === 2) {
												return (
													<span>
														<strong>{parts[0]}</strong>@{parts[1]}
													</span>
												)
											} else return email
										})()}
									</a>
								</p>
								<p className={'contact-info phone'}>
									<a href={`tel:${data.phone}`}>
										{data.phone.replace(/(.{2})/g, '$1 ')}
									</a>
								</p>
								<p>{data['tekst-onder']}</p>
								<p>
									Voor nieuws en info, kijk op mijn Facebook pagina:{' '}
									<a
										target='_blank'
										rel='noreferrer'
										href={data.facebook}
										className={'with-icon'}
									>
										Mari Ann
									</a>
								</p>
								<div className={'image-container desktop-only'}>
									<img
										alt='Foto met Mari Ann in een natuurlandschap'
										src={landscapeImage}
									/>
								</div>
							</div>
						</Element>
					</div>
				</div>
			</div>
			<div className={'block block-quote'}>
				<div className={'block-content'}>
					<ReactMarkdown>{data.quote}</ReactMarkdown>
				</div>
			</div>
			<div className={'block block-photo'}>
				<div className={'block-content'}>
					<div className={'image-container'}>
						<img
							alt='Foto met Mari Ann in een natuurlandschap'
							src={landscapeImage}
						/>
					</div>
				</div>
			</div>
			<div className={'block block-footer'}>
				<div className={'block-content'}>
					<div className={'subcontent'}>
						<div className={'left-container'}>
							<div className={'logo-container'}>
								<div className={'logo-icon'}>
									<img alt='Logo van Mari Ann' src={logoImage} />
								</div>
								<div className={'logo-text'}>
									<h1>Mari Ann</h1>
									<h3>Vocals & piano</h3>

									<p className={'aqua desktop-only'}>
										<a
											target='_blank'
											rel='noreferrer'
											href={data.facebook}
											className={'with-icon'}
										>
											Facebook
										</a>
										,{' '}
										<a
											target='_blank'
											rel='noreferrer'
											href={data.youtube}
											className={'with-underline'}
										>
											YouTube
										</a>
									</p>
								</div>
							</div>
							<p className={'aqua mobile-only'}>
								<a
									target='_blank'
									rel='noreferrer'
									href={data.facebook}
									className={'with-icon'}
								>
									Facebook
								</a>
								,{' '}
								<a
									target='_blank'
									rel='noreferrer'
									href={data.youtube}
									className={'with-underline'}
								>
									YouTube
								</a>
							</p>
						</div>
						<div className={'right-container'}>
							<div className={'credits'}>
								<ReactMarkdown
									children={data.credits}
									components={{
										a: (props) => (
											<a href={props.href} target='_blank' rel='noreferrer'>
												{props.children}
											</a>
										),
									}}
								/>
							</div>
							<p>
								<a onClick={scrollToTop} href='/' className={'with-underline'}>
									Terug naar boven
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</main>
	)
}

export default App
